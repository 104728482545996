<template>
  <div>
    <div class="columns" v-if="!isUser">
      <div class="column">
        <label class="label">Name</label>
        <input
          v-model="contactDetails.name"
          name="name"
          v-validate="'required'"
          class="input"
          placeholder="Name"
        />
        <span v-if="errors.first('name')" class="help has-text-danger">
          {{ errors.first('name') }}
        </span>
      </div>
      <div class="column">
        <label class="label">Email</label>
        <input
          v-model="contactDetails.email"
          name="email"
          v-validate="'required|email'"
          class="input"
          placeholder="Email"
        />
        <span v-if="errors.first('email')" class="help has-text-danger">
          {{ errors.first('email') }}
        </span>
      </div>
    </div>
    <div v-else-if="productKey" class="content">
      <p class="title is-6 has-text-soft">
        What would you like us to investigate?
      </p>
      <div class="columns is-multiline filter-menu-dropdown">
        <div
          :class="['column py-2', productKey === 'options' ? 'is-12' : 'is-4']"
          v-for="(category, index) in productCategories[productKey]"
          :key="index"
        >
          <span class="radio-tick">
            <checkbox
              name="categories"
              :value="category"
              v-model="enquiry.categories"
              v-validate="'required'"
            >
              <span>{{ category.title }}</span>
            </checkbox>
          </span>
        </div>
      </div>
      <span v-if="errors.first('categories')" class="help has-text-danger">
        Please select at least one of the above options
      </span>
    </div>
    <div class="content">
      <p class="label">Please describe your issue:</p>
      <p v-if="product !== 'General'">
        <span v-if="product === 'Options check'">
          If you know the option code that this issue relates to, please clearly
          state this information.
        </span>
      </p>
      <textarea
        rows="4"
        name="comments"
        v-model="enquiry.comments"
        class="textarea field has-fixed-size"
        v-validate="'required|min:20'"
      />
      <span v-if="errors.first('comments')" class="help has-text-danger">
        Please add more detail to allow us to resolve your issue - minumum 20
        characters
      </span>
    </div>
    <div class="field filter-menu-dropdown" v-if="$experian">
      <span class="radio-tick">
        <checkbox name="contactByPhone" v-model="contactByPhone">
          <span class="label has-text-soft">
            Would you like us to contact you by phone?
          </span>
        </checkbox>
      </span>
    </div>
    <div v-if="contactByPhone">
      <label class="label">Contact number</label>
      <input v-model="enquiry.telephone" class="input is-inline" />
    </div>
    <div class="buttons is-right">
      <button
        @click="submit"
        :class="{ 'is-loading': $wait.is('contact-form') }"
        :disabled="$wait.is('contact-form')"
        class="button is-info is-rounded"
      >
        Send
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  props: {
    product: {
      type: String,
      required: true
    },
    isUser: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    contactDetails: { email: null, name: null },
    enquiry: {
      comments: '',
      telephone: '',
      categories: []
    },
    contactByPhone: false
  }),
  computed: {
    productCategories: () => ({
      autoCheck: [
        { title: 'Stolen' },
        { title: 'Condition' },
        { title: 'Exported' },
        { title: 'Imported' },
        { title: 'High Risk' },
        { title: 'Other' },
        { title: 'Finance' },
        { title: 'Scrapped' },
        { title: 'Extra Data' },
        { title: 'Vehicle Specification' }
      ],
      companion: [
        { title: 'Valuation' },
        { title: 'Vehicle specification' },
        { title: 'Previous sale listing' },
        { title: 'Timeline' },
        { title: 'Market overview' },
        { title: 'Valuation certificate' },
        { title: 'Other' }
      ],
      options: [
        { title: 'Option missing that should be included' },
        { title: 'Option showing that should not be included' },
        { title: 'Other' }
      ]
    }),
    productKey() {
      const { product, $experian } = this
      if (product === 'AutoCheck') return 'autoCheck'
      else if (product === 'Companion' && !$experian) return 'companion'
      else if (product === 'Options check') return 'options'
      else return null
    }
  },
  methods: {
    async submit() {
      const valid = await this.$validator.validate()
      if (!valid) return

      const { contactDetails, enquiry, contactByPhone } = this
      this.$emit('submit', { contactDetails, enquiry, contactByPhone })
    }
  }
}
</script>
